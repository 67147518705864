<template>
  <div class="source-content">
    <!-- 导航栏 -->
    <NavBar title="运单管理" @clickRight="handleNavBarRight">
      <!-- 自定义右边样式 -->
      <template #right>
        <Icon name="search" class="font-18" />
      </template>
    </NavBar>

    <!-- 列表有数据 -->
    <PullRefresh v-model="refreshing" @refresh="onRefresh">
      <!-- 列表 -->
      <List
        class="list"
        v-model="loading"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        :finished="finished"
        finished-text="没有更多了"
        @load="getListData"
      >
        <div v-if="list.length">
          <Scard :item="item" v-for="item in list" :key="item.id"></Scard>
        </div>
      </List>
    </PullRefresh>
    <!-- 缺省页 -->
    <Empty v-if="!list.length" imgWidth="0.84rem" imgHeight="0.78rem" :image="emptyImg" description="暂无运单" />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/index';
import Empty from '@/components/Empty/index';
import { Icon, Button, PullRefresh, List } from 'vant';
import { defineComponent, onBeforeUnmount, ref, onMounted } from '@vue/composition-api';
import Scard from './components/card';
import { getBillSearch } from '@api/waybill';
import { useRouter } from '@/utils/compsitionHack';
import { PageEnum } from '@/enums/pageEnum';

import { ENTRUST_KEY } from '@/views/agreement/constants';
import { CARRIER_KEY } from '@/views/mine/message/constants';

export default defineComponent({
  name: 'Source',
  components: { Scard, NavBar, Icon, Button, Empty, PullRefresh, List },
  setup(_, ctx) {
    // 改变页面背景颜色
    onMounted(() => {
      // 清除掉合同留下的缓存
      window.localStorage.removeItem(ENTRUST_KEY);
      window.localStorage.removeItem(CARRIER_KEY);

      document.querySelector('body').setAttribute('style', 'background-color:#f5f5f5');
    });

    const router = useRouter();

    // 缺省页图片
    const emptyImg = require('@/assets/ydkb.png');

    const refreshing = ref(false);
    const loading = ref(false);
    const finished = ref(false);
    const error = ref(false);
    const pageNum = ref(1);

    // 获取列表数据
    const getListData = async () => {
      // 如果下拉刷新, 清空数据
      if (refreshing.value) {
        list.value = [];
        setTimeout(() => {
          refreshing.value = false;
        }, 1000);
      }
      // 获取运单列表接口
      const [e, res] = await ctx.root.$await(
        getBillSearch({
          pageNum: pageNum.value,
          pageSize: 10
        })
      );
      if (e) {
        // 如果请求失败，显示失败文案
        setTimeout(() => {
          error.value = true;
        }, 1000);
      } else {
        const data = res.data;
        if (data.records.length) {
          list.value = list.value.concat(data.records);
          pageNum.value++;
        } else {
          // 如果没有数据了，显示完成文案
          setTimeout(() => {
            finished.value = true;
          }, 1000);
        }
      }
      setTimeout(() => {
        loading.value = false;
        refreshing.value = false;
      }, 1000);
    };

    // 下拉刷新操作
    const onRefresh = () => {
      // 清空列表数据
      pageNum.value = 1;
      finished.value = false;
      error.value = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      getListData();
    };
    // 运单列表
    const list = ref([]);

    // 点击按钮
    const handleClickBtn = (status, sign, id) => {
      if (status === 30 && !sign) {
        router.push({
          path: PageEnum.CONTRACT_ONLINE,
          query: { driverId: 156, routerBack: 1 }
        });
      } else if (status === 30 && sign) {
        router.push({
          path: PageEnum.WAYBILL_GOODSINFO,
          query: { id: id, type: 0 }
        });
      } else if (status === 40) {
        router.push({
          path: PageEnum.WAYBILL_GOODSINFO,
          query: { id: id, type: 1 }
        });
      }
    };

    // 跳到详情页
    const handleToDetail = id => {
      router.push({ path: PageEnum.DRIVER_WAYBILL_DETAIL, query: { id } });
    };
    // 点击导航右边按钮
    const handleNavBarRight = () => {
      // TODO: 搜索功能
    };

    // 卸载组件前
    onBeforeUnmount(() => {
      document.querySelector('body').removeAttribute('style');
    });

    return {
      refreshing,
      loading,
      error,
      finished,
      pageNum,
      list,
      emptyImg,
      onRefresh,
      handleToDetail,
      handleNavBarRight,
      getListData,
      handleClickBtn
    };
  }
});
</script>
<style lang="less" scoped>
@import './styles/index.less';
</style>
<style>
.van-pull-refresh__head {
  margin-top: 0.06rem;
}
</style>
